import { memo } from 'react';

interface PointCloudCalculationsProps {
  calculationsNav: React.ReactNode;
}

const PointCloudCalculations_: React.FC2<PointCloudCalculationsProps> = ({ calculationsNav }) => {
  return <div className="flex flex-col space-y-4 divide-y divide-[#515256]">{calculationsNav}</div>;
};

export const PointCloudCalculations = memo(PointCloudCalculations_);
