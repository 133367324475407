import classNames from 'classnames';
import { FormikConfig, FormikHelpers } from 'formik';
import { omit } from 'lodash/fp';
import { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { InfoContent } from '../../../components/InfoContent';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikCheckBox } from '../../../components/formik/FormCheckBox';
import { FormikImageInput } from '../../../components/formik/FormImageInput';
import { FormikInput } from '../../../components/formik/FormInput';
import { FormikTextArea } from '../../../components/formik/FormTextArea';
import { PROJECTS } from '../../../graphql/project';
import { ORGANISATIONS } from '../../../graphql/organisation';
import { usePreviewUpload, useUpload } from '../../../hooks/useUpload';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import {
  Project,
  useProjectDeleteMutation,
  useProjectStartMutation,
  useProjectStartWithPythagorasFilesMutation,
  useProjectUpdateMutation,
  useProjectUpdatePreviewImageMutation,
} from '../../../types/graphqlTypes';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(translations.isRequired).max(120, translations.maxLength120),
        description: yup.string(),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  name: string;
  description: string;
  previewImage?: FileList;
  shouldMerge: boolean;
}

const useTranslations = () => {
  const namePlaceholder = useT('enter a project name', { swc: true });
  const descriptionPlaceholder = useT('enter a project description', { swc: true });
  const filePlaceholder = useT('choose file', { swc: true });
  const previewImagePlaceholder = useT('preview image', { swc: true });
  const mergePointClouds = useT('merge pointclouds', { swc: true });
  return { namePlaceholder, descriptionPlaceholder, filePlaceholder, previewImagePlaceholder, mergePointClouds };
};

interface ProjectModalProps {
  onClose: () => void;
  open?: boolean;
  project?: Pick<Project, 'id' | 'name' | 'description' | 'previewUrl'>;
  createInfo?: { projectId: string; fileNames: string[] };
  isPythagorasFile?: boolean;
}
const ProjectModal_: React.FC2<ProjectModalProps> = ({
  onClose: onCloseProps,
  open,
  project,
  createInfo,
  isPythagorasFile,
}) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const [startProject] = useProjectStartMutation();
  const [startProjectWithPythagorasFiles] = useProjectStartWithPythagorasFilesMutation();
  const [updateProject] = useProjectUpdateMutation();
  const [deleteProject] = useProjectDeleteMutation();
  const [setAndUploadPreview] = usePreviewUpload();
  const [loading, setLoading] = useState(false);

  const onClose = useCallback(
    ({ helpers }: { helpers?: FormikHelpers<FormValues> } = {}) => {
      onCloseProps();
      // Because of transition
      setTimeout(() => {
        helpers?.resetForm();
        setLoading(false);
      }, 250);
    },
    [onCloseProps],
  );

  const onCancel = useCallback(() => {
    if (createInfo)
      deleteProject({
        variables: { projectId: createInfo.projectId },
        update: (cache) => cache.evict({ id: `Project:${createInfo.projectId}` }),
      });
    onClose();
  }, [createInfo, deleteProject, onClose]);

  const onSuccess = useCallback((args: { helpers: FormikHelpers<FormValues> }) => onClose(args), [onClose]);

  const uploadPreview = useCallback(
    async ({ previewImage, projectId }: { previewImage?: FileList; projectId: string }) => {
      if (previewImage) {
        const file = (previewImage ? Array.from(previewImage) : [])[0];
        if (!file) return;
        await setAndUploadPreview(file, projectId);
      }
    },
    [setAndUploadPreview],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async ({ previewImage, ...values }, helpers) => {
      setLoading(true);

      if (!project) {
        if (!createInfo) return;
        if (!isPythagorasFile)
          return startProject({
            variables: { ...values, organisationId, ...createInfo },
            refetchQueries: [{ query: PROJECTS, variables: { organisationId } }, { query: ORGANISATIONS }],
            awaitRefetchQueries: true,
            onCompleted: async (data) => {
              const project = data.projectStart;
              await uploadPreview({ previewImage, projectId: project.id });
              onSuccess({ helpers });
            },
          });
        return startProjectWithPythagorasFiles({
          variables: {
            ...values,
            organisationId,
            cloudName: createInfo.fileNames[0],
            ...omit('fileNames', createInfo),
          },
          refetchQueries: [{ query: PROJECTS, variables: { organisationId } }],
          awaitRefetchQueries: true,
          onCompleted: async (data) => {
            const project = data.projectStartWithPythagorasFiles;
            await uploadPreview({ previewImage, projectId: project.id });
            onSuccess({ helpers });
          },
        });
      }

      return updateProject({
        variables: { ...values, projectId: project.id },
        onCompleted: async () => {
          await uploadPreview({ previewImage, projectId: project.id });
          onSuccess({ helpers });
        },
      });
    },
    [
      project,
      updateProject,
      createInfo,
      isPythagorasFile,
      startProject,
      organisationId,
      startProjectWithPythagorasFiles,
      uploadPreview,
      onSuccess,
    ],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: {
        name: project?.name || '',
        description: project?.description || '',
        files: undefined,
        shouldMerge: false,
      },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema, project],
  );

  return (
    <CreateModal
      title={project ? <T _str="update project" swc /> : <T _str="add a new project" swc />}
      createButtonTitle={project ? <T _str="update project" swc /> : <T _str="create project" swc />}
      formik={formik}
      onClose={onCancel}
      open={open}
      isSubmitting={loading}
      // We want to prevent that user closes model accidentally after uploading big file
      onlyCloseOnButtonClick={!!createInfo}
    >
      {!project && (
        <InfoContent
          type="warning"
          title={<T _str="Processing your file can take up some time" swc />}
          description={
            <T _str="After uploading your file, it could take some time to render your project. You will get a notification when your project is ready to use" />
          }
        />
      )}
      <div className={classNames('grid grid-cols-1 gap-6 mb-6', !project && 'mt-6')}>
        <FormikImageInput
          name="previewImage"
          label={<T _str="preview image" swc />}
          title={translations.previewImagePlaceholder}
          previewUrl={project?.previewUrl || ''}
        />
        <FormikInput autoFocus name="name" label={<T _str="name" swc />} placeholder={translations.namePlaceholder} />
        <FormikTextArea
          name="description"
          label={<T _str="description (optional)" swc />}
          placeholder={translations.descriptionPlaceholder}
        />
        {!isPythagorasFile && (
          <FormikCheckBox
            name="shouldMerge"
            label={translations.mergePointClouds}
            className={!createInfo?.fileNames.length || createInfo?.fileNames.length < 2 ? 'hidden' : ''}
          />
        )}
      </div>
    </CreateModal>
  );
};

export const ProjectModal = memo(ProjectModal_);
