import { Listbox, Transition, ListboxOptions, ListboxButton, ListboxOption } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Children, MouseEventHandler, PropsWithChildren, ReactElement, memo, useCallback, useMemo } from 'react';
import { T } from '../../../translation/src';

interface OptionProps<Value extends string | number | any[] = any> {
  value: Value;
}

const Option_: React.FC2<OptionProps> = ({ value, children }) => {
  return (
    <ListboxOption
      className={({ focus }) =>
        `relative select-none py-2 pl-10 pr-4 cursor-pointer  ${
          focus
            ? 'group bg-neon-green-300 dark:bg-eerie-black dark:text-white'
            : 'bg-white text-gray-900 dark:bg-[#313131] dark:text-white'
        }`
      }
      value={value}
    >
      {({ selected }) => (
        <>
          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{children}</span>
          {selected ? (
            <span
              className={classNames(
                'text-neon-green-700 dark:text-neon-green-300',
                'absolute inset-y-0 left-0 flex items-center pl-3',
              )}
            >
              <CheckIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </ListboxOption>
  );
};
export const Option = memo(Option_);

export const Divider: React.FC2 = () => {
  return (
    <Listbox.Label>
      <div className="border-t-2 border-black dark:border-white" />
    </Listbox.Label>
  );
};

export interface SelectProps<Value extends string | number | any | any[] = string> {
  value: Value | undefined;
  name?: string;
  onChange: (event: { target: { value: Value; name: string } }) => void;
  placeholder?: React.ReactNode;
  isLabelPlaceholder?: boolean;
  className?: string;
  id?: string;
  multiple?: boolean;
  clearable?: boolean;
  error?: boolean;
  disabled?: boolean;
}
const Select_ = <Value extends string | number | any[] = string>({
  value,
  name,
  onChange: propsOnChange,
  children,
  placeholder,
  isLabelPlaceholder,
  className,
  multiple,
  clearable,
  error,
  disabled,
}: PropsWithChildren<SelectProps<Value>>): ReactElement<any, any> | null => {
  const onChange = useCallback(
    (value: any) => {
      propsOnChange?.({ target: { value, name: name || '' } });
    },
    [name, propsOnChange],
  );

  const onClear: MouseEventHandler<HTMLSpanElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      propsOnChange({ target: { value: (multiple ? [] : '') as Value, name: name || '' } });
    },
    [multiple, name, propsOnChange],
  );

  const showClear = useMemo(() => {
    if (!clearable) return false;
    if (!multiple) return !!value;
    return !!value && !!(value as any[]).length;
  }, [multiple, value, clearable]);

  const selectedValueLabel = useMemo(() => {
    if (multiple)
      return (
        <span>
          {(value as any).length} <T _str="items selected" />
        </span>
      );
    let foundLabel = '';
    Children.toArray(children).some((child) => {
      // @ts-ignore
      if (child.props.value !== value) return false;
      // @ts-ignore
      foundLabel = child.props.children;
      return true;
    });
    return foundLabel;
  }, [children, multiple, value]);

  return (
    <div className={classNames(!className?.includes('w-') && 'w-full', 'text-sm h-10 cursor-pointer', className)}>
      <Listbox value={value} onChange={onChange} multiple={multiple} disabled={disabled}>
        <div className="relative h-full">
          <ListboxButton
            className={classNames(
              'bg-white border border-gray-300 hover:border-gray-400 dark:bg-[#313131] dark:text-white h-full',
              'relative w-full py-2 pl-3 pr-8 text-left border  rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 sm:text-sm',
              !!error && 'border-red-500',
            )}
          >
            {placeholder && isLabelPlaceholder && <span className="text-gray-400 truncate">{placeholder}: </span>}
            {placeholder && !isLabelPlaceholder && !selectedValueLabel && (
              <span className="text-gray-400 truncate">{placeholder} </span>
            )}
            <span className={classNames('truncate', isLabelPlaceholder ? 'font-bold ml-auto float-right' : 'block')}>
              {selectedValueLabel}
            </span>
            <span
              className={classNames(
                'absolute inset-y-0 right-0 flex items-center pr-2',
                !showClear && 'pointer-events-none',
              )}
              {...(showClear && { onClick: onClear })}
            >
              {showClear ? (
                <XMarkIcon
                  className={classNames('text-gray-400 dark:text-neon-green-300', ' w-5 h-5')}
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className={classNames('text-neon-green-700 dark:text-neon-green-300', 'w-5 h-5')}
                  aria-hidden="true"
                />
              )}
            </span>
          </ListboxButton>
          <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions
              className={classNames(
                'bg-white ring-black dark:bg-[#313131] dark:ring-white',
                'absolute z-40 w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-opacity-5 focus:outline-none sm:text-sm',
              )}
            >
              {children}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
export const Select = memo(Select_) as typeof Select_;
