import { ColorPopover } from '../../../components/Popover/ColorPopover';
import { Annotation, Color, useProjectEditAnnotationColorMutation } from '../../../types/graphqlTypes';
import { memo, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

const EditAnnotationColors_: React.FC2<{ annotations: Annotation[]; projectId: string }> = ({
  annotations,
  projectId,
}) => {
  const [editAnnotationColor] = useProjectEditAnnotationColorMutation();

  const color = useMemo(() => {
    const color = annotations[0].annotationColor || { r: 255, g: 0, b: 0, a: 1 };
    for (const annotation of annotations) {
      if (!isEqual(annotation.annotationColor || { r: 255, g: 0, b: 0, a: 1 }, color)) {
        return { r: 150, g: 150, b: 150, a: 1 };
      }
    }
    return color;
  }, [annotations]);

  const onEditAnnotationColor = useCallback(
    (color: Color) => {
      editAnnotationColor({
        variables: {
          annotationIds: annotations.map((annotation) => annotation.identifier),
          projectId: projectId,
          newColor: color,
        },
      });
    },
    [annotations, editAnnotationColor, projectId],
  );

  return (
    <div className="flex items-center w-full">
      <ColorPopover value={color} onChange={onEditAnnotationColor} />
    </div>
  );
};

export const EditAnnotationColors = memo(EditAnnotationColors_);
