import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  Square2StackIcon,
  PencilIcon,
  TrashIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { SelectItem } from '../../../components/selects/SelectItem';
import useOpen from '../../../hooks/useOpen';
import { T } from '../../../translation/src';
import { Project } from '../../../types/graphqlTypes';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectDeleteModal } from '../ProjectDeleteModal';
import { ProjectDuplicateModal } from '../ProjectDuplicateModal';
import { ProjectModal } from '../ProjectModal';
import { useExportDXF } from '../../../hooks/potree/useExportDXF';
import { useAnnotations } from '../../../hooks/potree/useRenderer';

const projectNameClassName =
  'inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-md dark:text-white dark:hover:text-neon-green-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75';

interface ProjectDetailSelectProps {
  project: Pick<Project, 'id' | 'name' | 'description' | 'previewUrl'>;
  isReadOnly: boolean;
}
const ProjectDetailSelect_: React.FC2<ProjectDetailSelectProps> = ({ project, isReadOnly }) => {
  const {
    open: modalOpen,
    value,
    onClose: closeModal,
    onOpenWithValue: openModal,
  } = useOpen<'update' | 'settings' | 'delete' | 'duplicate' | 'export' | 'history'>(false);
  const { organisationId } = useParams();
  const [exportDXF] = useExportDXF();
  const [{ indexedAnnotations }] = useAnnotations();

  const onDeleteComplete = useCallback(() => {
    /**
     * TODO: find better way to navigate and destroy everything
     * case: click on project with map view enabled, and click back on logo (https://pointorama.atlassian.net/jira/software/projects/PK/boards/2?selectedIssue=PK-7)
     */
    window.location.href = `${window.location.origin}/organisations/${organisationId}/projects`;
    // navigate(`/organisations/${organisationId}/projects`);
  }, [organisationId]);

  if (isReadOnly)
    return (
      <div className={projectNameClassName}>
        <div>{project.name}</div>
      </div>
    );

  return (
    <>
      <Menu as="div" className="relative inline-block w-80" onChange={console.log}>
        <Menu.Button className={projectNameClassName}>
          <div className="truncate">{project.name}</div>
          <ChevronDownIcon
            className="flex-shrink-0 w-5 h-5 ml-2 -mr-1 text-neon-green-400 dark:text-neon-green-300"
            aria-hidden="true"
          />
        </Menu.Button>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-64 mt-2 origin-top-right bg-white dark:bg-[#242424] divide-y divide-black rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <SelectItem icon={PencilIcon} onClick={() => openModal('update')}>
                <T _str="edit information" swc />{' '}
              </SelectItem>
            </div>
            <div className="px-1 py-1">
              <SelectItem icon={Square2StackIcon} onClick={() => openModal('duplicate')}>
                <T _str="duplicate" swc />{' '}
              </SelectItem>
              <SelectItem disabled={!indexedAnnotations.length} icon={ArrowDownTrayIcon} onClick={() => exportDXF()}>
                <T _str="export to DXF" swc />
              </SelectItem>
              <SelectItem icon={TrashIcon} onClick={() => openModal('delete')}>
                <T _str="delete" swc />
              </SelectItem>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <ProjectModal onClose={closeModal} open={modalOpen && value === 'update'} project={project} />
      <ProjectDeleteModal onClose={onDeleteComplete} open={modalOpen && value === 'delete'} project={project} />
      <ProjectDuplicateModal onClose={closeModal} open={modalOpen && value === 'duplicate'} project={project} />
    </>
  );
};

export const ProjectDetailSelect = memo(ProjectDetailSelect_);
