import { memo, SVGProps, useMemo } from 'react';
import { Popover } from '.';

interface GradientProps extends SVGProps<SVGSVGElement> {
  colors: {
    color: number[];
    percent: string;
  }[];
  name: string;
}
const Gradient_: React.FC2<GradientProps> = ({ colors, name, ...props }) => {
  return (
    <svg width="1.5rem" height="1.5rem" {...props}>
      <defs>
        <linearGradient id={name} gradientTransform="rotate(90)">
          {colors.map(({ color, percent }, i) => (
            <stop key={i} offset={percent} stopColor={`rgb(${color[0]}, ${color[1]}, ${color[2]})`}></stop>
          ))}
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill={`url("#${name}")`}></rect>
    </svg>
  );
};
const Gradient = memo(Gradient_);

const convertGradient = (values: (typeof Potree.Gradients)[0]) =>
  values
    ?.map(([weight, values]) => ({
      color: values.toArray().map((v: number) => v * 255),
      percent: `${100 - weight * 100}%`,
    }))
    .reverse() || [];

interface GradientPopoverProps {
  value: string;
  onChange: (value: string) => void;
}
const GradientPopover_: React.FC2<GradientPopoverProps> = ({ value, onChange }) => {
  const buttonChild = useMemo(() => {
    const values = Potree.Gradients[value];
    const colors = convertGradient(values);

    return (
      <div className="w-10 h-10 overflow-hidden border border-gray-500 rounded cursor-pointer">
        <Gradient name={'button-child-svg'} colors={colors} width="2.5rem" height="2.5rem" />
      </div>
    );
  }, [value]);

  return (
    <Popover buttonChild={buttonChild} panelClassName="w-auto">
      {({ open, close }) => (
        <div className="grid w-32 grid-cols-4 gap-2">
          {Object.entries(Potree.Gradients).map(([name, values]) => {
            const colors = convertGradient(values);
            return (
              <div
                className="w-6 h-6 overflow-hidden border border-gray-500 rounded cursor-pointer"
                key={name}
                onClick={() => {
                  close();
                  onChange(name);
                }}
              >
                <Gradient name={name} colors={colors} />
              </div>
            );
          })}
        </div>
      )}
    </Popover>
  );
};

export const GradientPopover = memo(GradientPopover_);
