import { FunctionComponent, memo, SVGProps } from 'react';
import { HeroIcon } from '../../types';

interface IconBoxProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon:
    | HeroIcon
    | FunctionComponent<
        SVGProps<SVGSVGElement> & {
          title?: string;
        }
      >;
}
const IconBox_: React.FC2<IconBoxProps> = ({ icon: Icon, ...props }) => {
  return (
    <div
      className="flex items-center justify-center cursor-pointer w-9 h-9 hover:bg-neon-green-300 dark:hover:bg-[#868994] dark:hover:text-neon-green-300"
      {...props}
    >
      <Icon className="w-4 h-4" />
    </div>
  );
};
export const IconBox = memo(IconBox_);
