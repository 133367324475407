import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import ArrowExpandIcon from '../../../assets/icons/arrow-expand.svg?react';
import FitToScreenIcon from '../../../assets/icons/fit-to-screen.svg?react';
import SphereIcon from '../../../assets/icons/sphere.svg?react';
import { useScreenTools } from '../../../hooks/potree/useScreenTools';
import { Project } from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { IconBox } from '../../../components/IconBox';
import {
  ProjectProjectionSystemModal,
  ProjectProjectionSystemModalProps,
} from '../../project/ProjectProjectionSystemModal';
import useOpen from '../../../hooks/useOpen';
import { Tooltip } from '../../../components/Tooltip';
import { T } from '../../../translation/src';

interface ScreenToolsProps {
  project?: Pick<Project, 'id' | 'mapVisible' | 'settings'>;
}

const MapTool_: React.FC2<ScreenToolsProps> = ({ className, project, ...props }) => {
  const [{ toggleMapOverlay }, , { mapOverlayEnabled }] = useScreenTools({ project });
  const { onClose: closeMapModal, open: mapModalOpen, onOpen: openMapModal } = useOpen();

  const onclickMap = useCallback(() => {
    if (project?.settings?.projectionSystem) {
      toggleMapOverlay();
    } else {
      openMapModal();
    }
  }, [project?.settings?.projectionSystem, openMapModal, toggleMapOverlay]);

  const onMapModalSuccess: Required<ProjectProjectionSystemModalProps>['onSuccess'] = useCallback(() => {
    toggleMapOverlay();
    closeMapModal();
  }, [toggleMapOverlay, closeMapModal]);

  return (
    <div className={classNames('z-10 w-auto', className)}>
      <Tooltip message={mapOverlayEnabled ? <T _str="hide map" swc /> : <T _str="show map" swc />} delay={0}>
        <div
          className="relative flex overflow-hidden bg-gray-800 border-2 border-gray-800 rounded cursor-pointer hover:border-neon-green-300"
          onClick={onclickMap}
        >
          <img
            src="https://productionpointorama.blob.core.windows.net/public/map.png"
            alt="map"
            className={classNames('w-10 h-10', mapOverlayEnabled && 'opacity-50')}
          />
          {mapOverlayEnabled && (
            <div className="absolute text-gray-800 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <SphereIcon className="w-8 h-8" />
            </div>
          )}
        </div>
      </Tooltip>
      <div>
        {project && (
          <ProjectProjectionSystemModal
            open={mapModalOpen}
            onClose={closeMapModal}
            project={project}
            onSuccess={onMapModalSuccess}
          />
        )}
      </div>
    </div>
  );
};

const ScreenTools_: React.FC2<ScreenToolsProps> = ({ className, project, ...props }) => {
  const [{ fitToScreen, onFullScreen }, { onZoomInStart, onZoomOutStart, onZoomEnd }] = useScreenTools({ project });
  return (
    <div
      className={classNames(
        'flex bg-white dark:bg-black dark:border-gray-700 dark:text-white rounded-lg divide-x-2 divide-gray-700 z-10 w-auto',
        className,
      )}
      {...props}
    >
      {/* <IconBox icon={CubeIcon} */}
      {document.fullscreenEnabled && (
        <Tooltip message={<T _str="expand" swc />} delay={0}>
          <IconBox icon={ArrowExpandIcon} onClick={onFullScreen} />
        </Tooltip>
      )}
      <Tooltip message={<T _str="fit to screen" swc />} delay={0}>
        <IconBox icon={FitToScreenIcon} onClick={fitToScreen} />
      </Tooltip>
      <Tooltip message={<T _str="zoom out" swc />} delay={0}>
        <IconBox icon={MinusIcon} onMouseDown={onZoomOutStart} onMouseUp={onZoomEnd} />
      </Tooltip>
      <Tooltip message={<T _str="zoom in" swc />} delay={0}>
        <IconBox icon={PlusIcon} onMouseDown={onZoomInStart} onMouseUp={onZoomEnd} />
      </Tooltip>
    </div>
  );
};

export const ScreenTools = memo(ScreenTools_);
export const MapTool = memo(MapTool_);
