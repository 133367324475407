import { Square2StackIcon, PencilIcon, TrashIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { ListItem } from '../../../components/ListItem';
import { StatusPill } from '../../../components/Pill';
import { FavoriteIcon } from '../../../components/favoriteIcon';
import { UserContext } from '../../../contexts/UserContext';
import { CURRENT_USER } from '../../../graphql/user';
import { useRecentProjects } from '../../../hooks/modules/project/useRecentProjects';
import useOpen from '../../../hooks/useOpen';
import { T } from '../../../translation/src';
import {
  Project,
  UploadStatus,
  User,
  useProjectRelaunchMutation,
  useUserUpdateFavoritesMutation,
} from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { MouseEventHandler, memo, useCallback, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DotsPopover } from '../../../components/Popover/DotsPopover';
import { ProjectDeleteModal } from '../ProjectDeleteModal';
import { ProjectDuplicateModal } from '../ProjectDuplicateModal';
import { ProjectModal } from '../ProjectModal';
import { ProjectProcessModal } from '../ProjectProcessModal';
import { ProjectSuspendedModal } from '../ProjectSuspendedModal';
import { ORGANISATIONS } from '../../../graphql/organisation';
import { PROJECTS } from '../../../graphql/project';

const ProjectCard_: React.FC2<{
  project: Pick<Project, 'name' | 'uploadStatus' | 'id' | 'previewUrl'> & {
    assignee?: Pick<User, 'id' | 'name'> | null;
    pointClouds: Array<{ pointCount?: number | null }>;
  };
  isReadOnly?: boolean;
}> = ({ className, project, isReadOnly }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    open: projectModalOpen,
    onClose: closeProjectModal,
    onOpen: openProjectModal,
  } = useOpen(false, { stopPropagationOnClick: true });
  const {
    open: deleteModalOpen,
    onClose: closeDeleteModal,
    onOpen: openDeleteModal,
  } = useOpen(false, { stopPropagationOnClick: true });
  const {
    open: duplicateModalOpen,
    onClose: closeDuplicateModal,
    onOpen: openDuplicateModal,
  } = useOpen(false, { stopPropagationOnClick: true });
  const {
    open: processModalOpen,
    onClose: closeProcessModal,
    onOpen: openProcessModal,
  } = useOpen(false, { stopPropagationOnClick: true });
  const {
    open: suspendedModalOpen,
    onClose: closeSuspendedModal,
    onOpen: openSuspendedModal,
  } = useOpen(false, { stopPropagationOnClick: true });
  const { 1: addRecentProject } = useRecentProjects();

  const onMouseDownProject: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.target.id?.includes('popover')) return;
      if (event.button !== 0 && event.button !== 1) return;
      if (project.uploadStatus === UploadStatus.Error) return;
      if (project.uploadStatus === UploadStatus.Progress) return openProcessModal(event);
      if (project.uploadStatus === UploadStatus.Suspended) return openSuspendedModal(event);
      addRecentProject(project.id);
      const path = `${location.pathname.includes('project') ? location.pathname : `${location.pathname}/projects`}/${
        project.id
      }`;
      const openInNewTab = event.button === 1 || (event.button === 0 && (event.ctrlKey || event.metaKey));
      if (openInNewTab) {
        const w = window.open(`${window.location.origin}${path}`, '_blank');
        w?.focus();
      } else {
        navigate(path);
      }
    },
    [
      addRecentProject,
      location.pathname,
      navigate,
      openProcessModal,
      openSuspendedModal,
      project.id,
      project.uploadStatus,
    ],
  );

  const [updateFavoriteProjects] = useUserUpdateFavoritesMutation();
  const currentUser = useContext(UserContext);
  const isFavorite = currentUser.favoriteProjectIds.includes(project.id);

  const onClickFavorites: MouseEventHandler<HTMLDivElement> = useCallback(async () => {
    await updateFavoriteProjects({
      variables: { projectId: project.id, inFavorites: !isFavorite },
      refetchQueries: [{ query: CURRENT_USER }],
      awaitRefetchQueries: true,
    });
  }, [isFavorite, project.id, updateFavoriteProjects]);

  const [relaunchProject] = useProjectRelaunchMutation();
  const { organisationId = '' } = useParams();

  const onRelaunch = useCallback(async () => {
    return relaunchProject({
      variables: {
        projectId: project.id,
      },
      refetchQueries: [{ query: PROJECTS, variables: { organisationId } }, { query: ORGANISATIONS }],
      awaitRefetchQueries: true,
    });
  }, [relaunchProject, project, organisationId]);

  return (
    <>
      <div className="relative">
        <div
          className={classNames(
            project.uploadStatus !== UploadStatus.Error &&
              'cursor-pointer hover:bg-gray-200 hover:border-neon-green-400 dark:hover:border-neon-green-400 dark:hover:bg-black',
            'flex flex-col w-full h-56 shadow-md bg-white hover:shadow-lg transition-shadow border border-gray-200 rounded-xl overflow-hidden',
            'dark:bg-[#242424] dark:border-gray-500',
            className,
          )}
          onMouseDown={onMouseDownProject}
        >
          <div className="relative flex items-center justify-center w-full text-white bg-gradient-to-br from-eerie-black to-ash-green-800 h-36">
            {project.previewUrl && (
              <img src={project.previewUrl} className="object-cover w-full h-36" alt="pointcloud_cover" />
            )}
            {project.uploadStatus === UploadStatus.Progress && (
              <div className="absolute -translate-x-1/2 left-1/2 top-1/2">
                <ArrowPathIcon className="w-6 h-6 text-white" />
              </div>
            )}
          </div>
          <div className="flex flex-col px-4 py-4">
            <div className="truncate">{project.name}</div>
            <div className="flex items-center justify-between">
              <span className="text-xs">
                <span className="font-light text-gray-400">
                  <T _str="uploaded by" swc />
                </span>{' '}
                <span>{project.assignee?.name || '-'}</span>
              </span>
              {project.uploadStatus !== UploadStatus.Success && (
                <StatusPill status={project.uploadStatus.toLocaleLowerCase()} />
              )}
            </div>
          </div>
        </div>
        <FavoriteIcon onClick={onClickFavorites} className="absolute left-3 top-3" checked={isFavorite} />
        {!isReadOnly && (
          <DotsPopover
            data-project-actions
            panelClassName={project.uploadStatus === UploadStatus.Progress ? 'w-72 mt-10' : 'mt-10'}
          >
            {project.uploadStatus !== UploadStatus.Progress ? (
              <>
                <ListItem onClick={openProjectModal} icon={PencilIcon}>
                  <T _str="edit information" swc />
                </ListItem>
                <hr className="my-2 border-t border-gray-300" />
                {project.uploadStatus === UploadStatus.Success && (
                  <ListItem onClick={openDuplicateModal} icon={Square2StackIcon}>
                    <T _str="duplicate" swc />
                  </ListItem>
                )}
                {project.uploadStatus === UploadStatus.Error && currentUser.isSuperAdmin && (
                  <ListItem
                    onClick={onRelaunch}
                    icon={ArrowPathIcon}
                    className="text-black bg-error-red-500 dark:hover:text-white"
                  >
                    <T _str="relaunch import" swc />
                  </ListItem>
                )}
                <ListItem onClick={openDeleteModal} icon={TrashIcon}>
                  <T _str="delete" swc />
                </ListItem>
              </>
            ) : (
              <ListItem onClick={openDeleteModal} icon={TrashIcon}>
                <T _str="cancel processing and delete" swc />
              </ListItem>
            )}
          </DotsPopover>
        )}
      </div>
      <ProjectModal onClose={closeProjectModal} open={projectModalOpen} project={project} />
      <ProjectDeleteModal onClose={closeDeleteModal} open={deleteModalOpen} project={project} />
      <ProjectDuplicateModal onClose={closeDuplicateModal} open={duplicateModalOpen} project={project} />
      <ProjectProcessModal onClose={closeProcessModal} open={processModalOpen} project={project} />
      <ProjectSuspendedModal onClose={closeSuspendedModal} open={suspendedModalOpen} project={project} />
    </>
  );
};

export const ProjectCard = memo(ProjectCard_);
