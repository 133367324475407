import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { keyBy, omit } from 'lodash/fp';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CalculatorIcon from '../../../assets/icons/calculator.svg?react';
import ChartBoxIcon from '../../../assets/icons/chart-box.svg?react';
import CommentsIcon from '../../../assets/icons/comments.svg?react';
import { Badge } from '../../../components/Badge';
import { TabPane, TabsContainer } from '../../../components/Tabs';
import { AnnotationContextGroup, ProjectionCalculation, VolumeCalculation } from '../../../contexts/AnnotationContext';
import { useAnnotations, useCADObjects } from '../../../hooks/potree/useRenderer';
import useQueryParams from '../../../hooks/useQueryParams';
import { useCustomSelector } from '../../../redux/store';
import { T, useT } from '../../../translation/src';
import { PointCloud, Project, ProjectByIdQuery } from '../../../types/graphqlTypes';
import { replaceURLParams } from '../../../utils/URLSearchParams';
import { AreaCalculations } from './AreaCalculations';
import { AreaProperties, CadAreaProperties } from './AreaProperties';
import { CalculationList } from './CalculationList';
import { Comments } from './Comments';
import { DistanceCalculations } from './DistanceCalculations';
import { CadDistanceProperties, DistanceProperties } from './DistanceProperties';
import { GroupCalculations } from './GroupCalculations';
import { GroupProperties } from './GroupProperties';
import { MapProperties } from './MapProperties';
import { MultiSelectCalculations } from './MultiSelectCalculations';
import { MultiSelectProperties } from './MultiSelectProperties';
import { PointCloudCalculations } from './PointCloudCalculations';
import { PointCloudProperties } from './PointCloudProperties';
import { CadPointProperties, PointProperties } from './PointProperties';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import { BoxProperties } from './BoxProperties';
import { PointClusterProperties } from './PointClusterProperties';
import { OrthophotoProperties } from './OrthophotoProperties';
import { OrthophotoLayersContext } from '../../../contexts/OrthophotoLayersContext';
import { PlaneProperties } from './PlaneProperties';
import { CadObject, ICadLine, ICadPoint, ICadPolygon } from '../../../utils/CADFileParser';

export enum Tabs {
  ANNOTATIONS = 'ANNOTATIONS',
  CALCULATIONS = 'CALCULATIONS',
  LAYERS = 'LAYERS',
}

export const useCalculationTranslations = () => {
  const volume = useT('volume', { swc: true });
  const elevationProfile = useT('elevation profile', { swc: true });
  const invalidCalculations = useT('This object contains invalid calculations');
  const otherCalculations = useT('other calculations in this project');
  return {
    ['VolumeCalculation' as const]: volume,
    ['ProjectionCalculation' as const]: elevationProfile,
    invalidCalculations,
    otherCalculations,
  };
};

interface RendererRightPanelProps {
  project?: Pick<Project, 'settings' | 'id' | 'mapVisible' | 'state'> & {
    pointClouds: Pick<
      PointCloud,
      'id' | 'cloudName' | 'displayName' | 'availableClasses' | 'hasClassifications' | 'pointCount'
    >[];
    comments?: ProjectByIdQuery['projectById']['comments'] | null;
  };
  closePanel: () => void;
  openPanel: () => void;
  panelOpen: boolean;
  hideArrow?: boolean;
}
const RendererRightPanel_: React.FC2<RendererRightPanelProps> = ({
  project,
  closePanel,
  openPanel,
  panelOpen,
  hideArrow,
}) => {
  const pointClouds = useMemo(() => project?.pointClouds || [], [project?.pointClouds]);
  const calculationTranslations = useCalculationTranslations();
  const { smDevice } = useDeviceSize();

  const { rightPanelTab: selectedTab } = useQueryParams(['rightPanelTab']);

  const location = useLocation();
  const navigate = useNavigate();

  const onChangeTab = useCallback(
    ({ key }: { key: string }) => {
      if (!panelOpen) openPanel();
      navigate({
        ...location,
        search: replaceURLParams({ params: { rightPanelTab: key }, search: location.search }),
      });
    },
    [location, navigate, openPanel, panelOpen],
  );
  const { selectedAnnotations, selectedPointCloudName, mapSelected, selectedCadItems, selectedOrthophotoItem } =
    useCustomSelector(
      (state) => state.rendererProvider,
      ['selectedAnnotations', 'selectedPointCloudName', 'mapSelected', 'selectedCadItems', 'selectedOrthophotoItem'],
    );
  const isSingleSelection = selectedAnnotations.length === 1;
  const [{ annotationsByIdentifier, annotations, calculationsByIdentifier }] = useAnnotations();
  const [{ cadObjectsByIdentifier }] = useCADObjects();
  const { orthophotoLayers } = useContext(OrthophotoLayersContext);
  const orthophotosByIdentifier = keyBy('identifier', orthophotoLayers);

  const selectedAnnotation =
    isSingleSelection &&
    selectedAnnotations[0]?.type === 'annotation' &&
    annotationsByIdentifier[selectedAnnotations[0].identifier];

  const selectedGroup =
    isSingleSelection &&
    selectedAnnotations[0]?.type === 'group' &&
    (annotations.find(
      (annotation) => annotation.identifier === selectedAnnotations[0].identifier,
    ) as AnnotationContextGroup);

  const selectedPointCloud = useMemo(
    () => pointClouds.find((pointCloud) => pointCloud.cloudName === selectedPointCloudName),
    [pointClouds, selectedPointCloudName],
  );
  const isSingleCadSelection = selectedCadItems?.length === 1;
  const selectedCadObject =
    isSingleCadSelection &&
    selectedCadItems[0].type === 'cadObject' &&
    cadObjectsByIdentifier[selectedCadItems[0].identifier];

  const selectedOrthophoto = selectedOrthophotoItem && orthophotosByIdentifier[selectedOrthophotoItem.identifier];

  const propertiesPanel = useMemo(() => {
    if (!project) return;
    if (selectedAnnotation && selectedAnnotation.__typename === 'PointAnnotation')
      return <PointProperties annotation={selectedAnnotation} />;
    if (selectedAnnotation && selectedAnnotation.__typename === 'AreaAnnotation')
      return <AreaProperties annotation={selectedAnnotation} />;
    if (selectedAnnotation && selectedAnnotation.__typename === 'DistanceAnnotation')
      return <DistanceProperties annotation={selectedAnnotation} />;
    if (selectedAnnotation && selectedAnnotation.__typename === 'BoxAnnotation')
      return <BoxProperties projectId={project.id} annotation={selectedAnnotation} />;
    if (selectedAnnotation && selectedAnnotation.__typename === 'PointCluster')
      return <PointClusterProperties projectId={project.id} pointCluster={selectedAnnotation} />;
    if (selectedAnnotation && selectedAnnotation.__typename === 'Plane')
      return <PlaneProperties projectId={project.id} plane={selectedAnnotation} />;
    if (selectedGroup) return <GroupProperties projectId={project.id} annotation={selectedGroup} />;
    if (selectedAnnotations.length > 1)
      return <MultiSelectProperties projectId={project.id} selection={selectedAnnotations} />;
    // We set a key on pointcloudproperties to set right height filters when switching between pointclouds (PK-203)
    if (selectedPointCloud) return <PointCloudProperties pointCloud={selectedPointCloud} key={selectedPointCloud.id} />;
    if (mapSelected && project) return <MapProperties project={project} />;
    const isCadPoint = (cadObject: CadObject | false | undefined): cadObject is ICadPoint => {
      return !!cadObject && cadObject.type === 'POINT';
    };
    const isCadLine = (cadObject: CadObject | false | undefined): cadObject is ICadLine => {
      return !!cadObject && cadObject.type === 'LINE';
    };
    const isCadPolygon = (cadObject: CadObject | false | undefined): cadObject is ICadPolygon => {
      return !!cadObject && cadObject.type === 'POLYGON';
    };
    if (isCadPoint(selectedCadObject)) return <CadPointProperties cadPoint={selectedCadObject} />;
    if (isCadLine(selectedCadObject)) return <CadDistanceProperties cadLine={selectedCadObject} />;
    if (isCadPolygon(selectedCadObject)) return <CadAreaProperties cadPolygon={selectedCadObject} />;
    if (selectedOrthophoto) return <OrthophotoProperties orthophoto={selectedOrthophoto} />;
    return null;
  }, [
    mapSelected,
    project,
    selectedAnnotation,
    selectedGroup,
    selectedPointCloud,
    selectedAnnotations,
    selectedCadObject,
    selectedOrthophoto,
  ]);

  const otherCalculationsNav = useMemo(() => {
    if (
      !(
        selectedAnnotation &&
        ['AreaAnnotation', 'DistanceAnnotation'].includes(selectedAnnotation.__typename as 'AreaAnnotation')
      )
    )
      return null;
    const otherCalculations = omit(selectedAnnotation.identifier, calculationsByIdentifier);

    return (
      <CalculationList
        title={calculationTranslations.otherCalculations}
        listedCalculations={Object.values(otherCalculations).flat()}
      />
    );
  }, [calculationTranslations.otherCalculations, calculationsByIdentifier, selectedAnnotation]);

  const calculationsOnPointCloudNav = useMemo(() => {
    if (!selectedPointCloud) return null;
    const calculationsOnPointCloud = Object.values(calculationsByIdentifier)
      .flat()
      .filter((calculation) => {
        const annotation = annotationsByIdentifier[calculation.annotationIdentifier];
        const annotationOverlapsPointCloud =
          (annotation?.__typename === 'AreaAnnotation' || annotation?.__typename === 'DistanceAnnotation') &&
          annotation?.points.some((point) => {
            return point.pointCloudId === selectedPointCloud.id;
          });
        return annotationOverlapsPointCloud;
      });
    return <CalculationList title="Calculations on this point cloud" listedCalculations={calculationsOnPointCloud} />;
  }, [annotationsByIdentifier, calculationsByIdentifier, selectedPointCloud]);

  const calculationsPanel = useMemo(() => {
    if (!project) return;
    if (selectedAnnotation && selectedAnnotation.__typename === 'AreaAnnotation')
      return (
        <AreaCalculations
          annotation={selectedAnnotation}
          calculations={(calculationsByIdentifier[selectedAnnotation.identifier] || []) as VolumeCalculation[]}
          otherCalculationsNav={otherCalculationsNav}
          project={project}
        />
      );
    if (selectedAnnotation && selectedAnnotation.__typename === 'DistanceAnnotation')
      return (
        <DistanceCalculations
          annotation={selectedAnnotation}
          calculations={(calculationsByIdentifier[selectedAnnotation.identifier] || []) as ProjectionCalculation[]}
          otherCalculationsNav={otherCalculationsNav}
          project={project}
        />
      );
    if (selectedGroup) return <GroupCalculations />;
    if (selectedAnnotations.length > 1) return <MultiSelectCalculations />;
    if (selectedPointCloud) return <PointCloudCalculations calculationsNav={calculationsOnPointCloudNav} />;
    return null;
  }, [
    selectedAnnotation,
    calculationsByIdentifier,
    otherCalculationsNav,
    calculationsOnPointCloudNav,
    selectedGroup,
    selectedAnnotations,
    selectedPointCloud,
    project,
  ]);

  const hasInvalidCalculations = useMemo(() => {
    if (!selectedAnnotation) return false;
    return calculationsByIdentifier[selectedAnnotation.identifier]?.some((calculation) => calculation.isOutDated);
  }, [calculationsByIdentifier, selectedAnnotation]);

  const commentsPanel = useMemo(() => {
    if (!project) return null;
    if (selectedAnnotation || selectedGroup) return <Comments project={project} />;
    return null;
  }, [project, selectedAnnotation, selectedGroup]);

  return (
    <div className="relative flex h-full">
      {!hideArrow && (
        <div className="absolute z-40 flex items-center justify-center w-8 h-8 transform -translate-y-1/2 rounded-full cursor-pointer text-neon-green-400 dark:text-ash-green-800 bg-eerie-black dark:bg-neon-green-300 top-1/2 -left-4">
          {panelOpen ? (
            <ChevronRightIcon className="w-4 h-4" onClick={closePanel} />
          ) : (
            <ChevronLeftIcon className="w-4 h-4" onClick={openPanel} />
          )}
        </div>
      )}
      <div
        className={classNames(
          'relative z-10 flex flex-col flex-shrink-0 h-full px-4 py-3 dark:bg-[#242424] dark:text-white border-t border-[#515256] overflow-auto',
          panelOpen ? (smDevice ? 'w-64' : 'w-96') : 'w-20',
        )}
      >
        {panelOpen && (
          <>
            <TabsContainer
              onChange={onChangeTab}
              defaultActiveKey={selectedTab || Tabs.ANNOTATIONS}
              tabWidth={smDevice ? '3.66rem' : '7.33rem'}
            >
              <TabPane key={Tabs.ANNOTATIONS}>
                <div className="inline-flex items-center space-x-2 select-none">
                  <ChartBoxIcon />{' '}
                  {!smDevice && (
                    <div>
                      <T _str="properties" swc />
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane key={Tabs.CALCULATIONS}>
                <div className="relative inline-flex items-center space-x-2 select-none">
                  <CalculatorIcon />{' '}
                  {!smDevice && (
                    <div>
                      <T _str="calculations" swc />
                    </div>
                  )}
                  {hasInvalidCalculations && (
                    <Badge
                      size="s"
                      type="warning"
                      className="absolute cursor-pointer -top-3 -right-3"
                      title={calculationTranslations.invalidCalculations}
                    />
                  )}
                </div>
              </TabPane>
              <TabPane key={Tabs.LAYERS}>
                <div className="inline-flex items-center space-x-2 select-none">
                  <CommentsIcon />{' '}
                  {!smDevice && (
                    <div>
                      <T _str="comments" swc />
                    </div>
                  )}
                </div>
              </TabPane>
            </TabsContainer>
            <div className="h-full overflow-auto">
              {selectedTab === Tabs.ANNOTATIONS && propertiesPanel}
              {selectedTab === Tabs.CALCULATIONS && calculationsPanel}
              {selectedTab === Tabs.LAYERS && commentsPanel}
            </div>
          </>
        )}
        {!panelOpen && (
          <div className="flex flex-col w-full h-full">
            <div
              className={classNames(
                'flex items-center justify-center cursor-pointer p-4 rounded-lg',
                selectedTab === Tabs.ANNOTATIONS && 'bg-neon-green-300 dark:bg-black',
              )}
              onClick={() => onChangeTab({ key: Tabs.ANNOTATIONS })}
            >
              <ChartBoxIcon />
            </div>
            <div
              className={classNames(
                'flex items-center justify-center cursor-pointer p-4 rounded-lg',
                selectedTab === Tabs.CALCULATIONS && 'bg-neon-green-300 dark:bg-black',
              )}
              onClick={() => onChangeTab({ key: Tabs.CALCULATIONS })}
            >
              <CalculatorIcon />
            </div>
            <div
              className={classNames(
                'flex items-center justify-center cursor-pointer p-4 rounded-lg',
                selectedTab === Tabs.LAYERS && 'bg-neon-green-300 dark:bg-black',
              )}
              onClick={() => onChangeTab({ key: Tabs.LAYERS })}
            >
              <CommentsIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const RendererRightPanel = memo(RendererRightPanel_);
