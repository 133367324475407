import { CreateModal } from '../../../components/Modal/CreateModal';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { T, useT } from '../../../translation/src';
import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormikFileInput } from '../../../components/formik/FormFileInput';
import { useUpload } from '../../../hooks/useUpload';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const useTranslations = () => {
  const filePlaceholder = useT('choose file', { swc: true });
  const fileLabel = useT('file', { swc: true });

  return {
    filePlaceholder,
    fileLabel,
  };
};

interface FormValues {
  orthophotoFile?: FileList;
}

export interface OrthophotoLayerModalProps {
  onClose: () => void;
  open?: boolean;
}
const OrthophotoLayerModal_: React.FC2<OrthophotoLayerModalProps> = ({ onClose, open }) => {
  const translations = useTranslations();
  const [executeAction] = useExecuteAction();
  const { projectId = '' } = useParams();
  const [_, { uploadFiles }] = useUpload();
  const [loading, setLoading] = useState(false);

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
        setLoading(false);
      }, 250);
    },
    [onClose],
  );

  const uploadOrthophoto = useCallback(
    async ({
      orthophotoFile,
      projectId,
      orthophotoId,
    }: {
      orthophotoFile?: FileList;
      projectId: string;
      orthophotoId: string;
    }) => {
      if (orthophotoFile) {
        const file = (orthophotoFile ? Array.from(orthophotoFile) : [])[0];
        if (!file) return;
        const fileName = `orthophoto${orthophotoId}.tif`;
        const renamedFile = new File([file], fileName, { type: file.type });
        await uploadFiles({ files: [renamedFile], projectId, withoutId: true });
      }
    },
    [uploadFiles],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async ({ orthophotoFile }, helpers) => {
      setLoading(true);
      const orthophotoId = uuid();
      const actionValues = {
        name: 'Orthophoto',
        layerId: orthophotoId,
      };

      await uploadOrthophoto({ orthophotoFile, projectId, orthophotoId }).then(() => {
        executeAction({
          type: 'ADD_ORTHOPHOTO_LAYER',
          action: { ...actionValues },
        });
      });
      onSuccess({ helpers });
    },
    [onSuccess, projectId, uploadOrthophoto, executeAction],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: {},
      onSubmit,
    }),
    [onSubmit],
  );

  return (
    <CreateModal
      title={<T _str="Add a new orthophoto layer" swc />}
      createButtonTitle={<T _str="upload" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={loading}
      alwaysCancellable
    >
      <FormikFileInput
        accept=".tif"
        label={`${translations.fileLabel} (.tif)`}
        title={`${translations.filePlaceholder} (.tif)`}
        name="orthophotoFile"
      />
    </CreateModal>
  );
};

export const OrthophotoLayerModal = memo(OrthophotoLayerModal_);
