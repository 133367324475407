import { MouseEventHandler, memo, useCallback, useContext } from 'react';
import { WmsLayer } from '../../../../types/graphqlTypes';
import { useCustomSelector } from '../../../../redux/store';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { ArrowDownIcon, ArrowUpIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import LayersIcon from '../../../../assets/icons/layers.svg?react';
import { ContextMenu } from '../../../../components/ContextMenu';
import { T } from '../../../../translation/src';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import { useSelectedWmsItem } from '../../../../hooks/potree/useSelectedWmsItem';
import { HideSelect, HideSelectorProps } from '../../../../components/HideSelector';
import { WmsLayersContextType } from '../../../../contexts/WmsLayersContext';
import { useToggleHide } from '../../../../hooks/modules/project/useToggleHide';
import { WMSLayerModalProps } from '../../../project/WMSLayerModal';
import classNames from 'classnames';
import { RendererContext } from '../../../../contexts/RendererContext';

const WMSLayerComponent_: React.FC2<{
  wmsLayer: WmsLayer;
  onUpdateLayer: (layer: WMSLayerModalProps['initialValues']) => void;
  index: number;
  isFirst?: boolean;
  isLast?: boolean;
}> = ({ wmsLayer, onUpdateLayer, index, isFirst, isLast }) => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const { selectedWmsItem } = useCustomSelector((state) => state.rendererProvider, ['selectedWmsItem']);
  const { toggleHide } = useToggleHide();
  const selected = selectedWmsItem?.identifier === wmsLayer.identifier;
  const { onClickWmsItem } = useSelectedWmsItem();
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    onClickWmsItem({ identifier: wmsLayer.identifier });
  }, [wmsLayer, onClickWmsItem]);
  const visible = wmsLayer.visible;
  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: wmsLayer.name,
    wmsLayerIdentifier: wmsLayer.identifier,
  });
  const [executeAction] = useExecuteAction();
  const onDeleteWmsLayer = useCallback(() => {
    executeAction({ action: { wmsLayerIdentifier: wmsLayer.identifier }, type: 'DELETE_WMS_LAYER' });
    viewer?.removeWmsLayer({ identifier: wmsLayer.identifier });
  }, [executeAction, viewer, wmsLayer.identifier]);

  const onToggleHide: Required<HideSelectorProps>['onClick'] = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      toggleHide({ identifiers: [wmsLayer.identifier], visible: !visible });
    },
    [toggleHide, wmsLayer.identifier, visible],
  );

  const onUpdate = useCallback(() => {
    onUpdateLayer({
      layerName: wmsLayer.layerName,
      name: wmsLayer.name,
      username: wmsLayer.username || '',
      password: wmsLayer.password || '',
      url: wmsLayer.url,
      layerId: wmsLayer.identifier,
    });
  }, [onUpdateLayer, wmsLayer]);

  const onMoveUp = useCallback(
    () =>
      executeAction({
        type: 'UPDATE_POSITION_WMS_LAYER',
        action: {
          toIndex: index - 1,
          identifiers: [{ identifier: wmsLayer.identifier }],
        },
      }),
    [executeAction, index, wmsLayer.identifier],
  );
  const onMoveDown = useCallback(
    () =>
      executeAction({
        type: 'UPDATE_POSITION_WMS_LAYER',
        action: {
          toIndex: index + 1,
          identifiers: [{ identifier: wmsLayer.identifier }],
        },
      }),
    [executeAction, index, wmsLayer.identifier],
  );

  if (nameEditable) return nameInputComponent || null;
  return (
    <ContextMenu
      buttonChild={
        <SelectItemContent
          onClick={onClick}
          icon={LayersIcon}
          active={selected}
          activeLevel={0}
          selectStyle="primary"
          idle={!visible}
          className={'relative pl-8'}
          onDoubleClick={onToggleNameEditable}
        >
          <div className="overflow-hidden">{wmsLayer.name}</div>
          <div className="flex justify-end flex-grow">
            <HideSelect hidden={!visible} onClick={onToggleHide} />
          </div>
        </SelectItemContent>
      }
      panelClassName="divide-y"
    >
      <div className="flex flex-col">
        <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
          <T _str="rename" swc />
        </SelectItemContent>
        <SelectItemContent icon={PencilIcon} onClick={onUpdate}>
          <T _str="update" swc />
        </SelectItemContent>
      </div>
      <div>
        <SelectItemContent icon={ArrowUpIcon} onClick={onMoveUp} disabled={isFirst}>
          <T _str="move up" swc />
        </SelectItemContent>
        <SelectItemContent icon={ArrowDownIcon} onClick={onMoveDown} disabled={isLast}>
          <T _str="move down" swc />
        </SelectItemContent>
      </div>
      <div className="flex flex-col">
        <SelectItemContent icon={TrashIcon} onClick={onDeleteWmsLayer}>
          <T _str="delete" swc />
        </SelectItemContent>
      </div>
    </ContextMenu>
  );
};
export const WMSLayerComponent = memo(WMSLayerComponent_);

interface WMSLayersProps {
  className?: string;
  wmsLayers: WmsLayersContextType['wmsLayers'];
  onUpdateLayer: (layer: WMSLayerModalProps['initialValues']) => void;
}

const WmsLayers_: React.FC2<WMSLayersProps> = ({ className, wmsLayers, onUpdateLayer }) => {
  return (
    <div className={classNames(className, 'py-2')}>
      {wmsLayers?.map((wmsLayer, index) => {
        return (
          <WMSLayerComponent
            wmsLayer={wmsLayer}
            key={wmsLayer.identifier}
            onUpdateLayer={onUpdateLayer}
            index={index}
            isFirst={index === 0}
            isLast={index === wmsLayers.length - 1}
          />
        );
      })}
    </div>
  );
};
export const WmsLayers = memo(WmsLayers_);
