import { Draggable } from '@hello-pangea/dnd';
import { FolderIcon, FolderMinusIcon, PencilIcon, TrashIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import PlaneIcon from '../../../../assets/icons/plane.svg?react';
import classNames from 'classnames';
import { MouseEventHandler, memo, useCallback, useMemo } from 'react';
import LocationIcon from '../../../../assets/icons/location.svg?react';
import VectorLineIcon from '../../../../assets/icons/vector-line.svg?react';
import VectorPolygonIcon from '../../../../assets/icons/vector-polygon.svg?react';
import CubeIcon from '../../../../assets/icons/cube.svg?react';
import ClusterIcon from '../../../../assets/icons/cluster-icon.svg?react';
import { ContextMenu } from '../../../../components/ContextMenu';
import { SelectItemContent } from '../../../../components/selects/SelectItem';
import { useIsMultiSelect } from '../../../../hooks/modules/renderer/useIsMultiSelect';
import { useRename } from '../../../../hooks/modules/renderer/useRename';
import { useRenameSubmit } from '../../../../hooks/modules/renderer/useRenameSubmit';
import { useUpdatePosition } from '../../../../hooks/modules/renderer/useUpdatePosition';
import { useExecuteAction } from '../../../../hooks/potree/useExecuteAction';
import { emitter } from '../../../../hooks/potree/useProject';
import useOpen from '../../../../hooks/useOpen';
import { useCustomSelector } from '../../../../redux/store';
import { T } from '../../../../translation/src';
import { Annotation as TypeAnnotation } from '../../../../types/graphqlTypes';
import { AnnotationGroupModal } from '../../../project/AnnotationGroupModal';
import { MoveAnnotationsGroupModal } from '../../../project/MoveAnnotationsGroupModal';
import { AnnotationsIconsWrapper } from './AnnotationsIconsWrapper';
import { useExportDXF } from '../../../../hooks/potree/useExportDXF';

const ICONS_BY_TYPE = {
  PointAnnotation: LocationIcon,
  DistanceAnnotation: VectorLineIcon,
  AreaAnnotation: VectorPolygonIcon,
  BoxAnnotation: CubeIcon,
  PointCluster: ClusterIcon,
  Plane: PlaneIcon,
};

export interface AnnotationProps {
  type: keyof typeof ICONS_BY_TYPE;
  annotation: Pick<TypeAnnotation, 'identifier' | 'groupIdentifier' | 'name'> & {
    index: number;
    visible?: boolean;
    type?: TypeAnnotation['type'];
  };
  active?: boolean;
  rootActive?: boolean;
  className?: string;
  groupIdentifier?: string;
  isFirst?: boolean;
  isLast?: boolean;
  onClickAnnotation: (
    args1: { identifier: string; type: 'group' | 'annotation'; groupIdentifier?: string },
    args2: { isCtrl?: boolean; isShift?: boolean },
  ) => void;
  rootIndex: number;
  isReadOnly: boolean;
}
const Annotation_: React.FC2<AnnotationProps> = ({
  type,
  active,
  annotation,
  className,
  rootActive,
  groupIdentifier,
  isFirst,
  isLast,
  onClickAnnotation,
  isReadOnly,
  rootIndex,
}) => {
  const identifier = annotation.identifier;

  const isMultiSelect = useIsMultiSelect();
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);
  const { onClose: closeCreateGroupModal, onOpen: openCreateGroupModal, open: createGroupModalOpen } = useOpen();
  const {
    onClose: closeMoveAnnotationsGroupModal,
    onOpenWithValue: openMoveAnnotationsGroupModal,
    open: moveAnnotationsGroupModalOpen,
  } = useOpen();
  const submitRename = useRenameSubmit();
  const [executeAction] = useExecuteAction();
  const [exportDXF] = useExportDXF();

  const [{ UpdatePositionItems }] = useUpdatePosition({
    annotationIdentifier: annotation.identifier,
    downDisabled: isLast,
    upDisabled: isFirst,
  });

  const onDeleteAnnotation = useCallback(() => {
    executeAction({ action: { annotationIdentifier: identifier }, type: 'DELETE_ANNOTATION' });
    // @TODO: investigate why project does not render automatically in this case...
    emitter.emit('RERENDER');
  }, [executeAction, identifier]);

  const onMoveAnnotationOutGroup = useCallback(() => {
    executeAction({
      type: 'MOVE_ANNOTATIONS_TO_GROUP',
      action: {
        annotationIdentifiers: selectedAnnotations.map((annotation) => annotation.identifier),
        groupIdentifier: null,
      },
    });
  }, [executeAction, selectedAnnotations]);

  const onDeleteAnnotations = useCallback(() => {
    executeAction({
      action: { identifiers: selectedAnnotations.map((annotation) => annotation.identifier) },
      type: 'DELETE_ANNOTATION_MULTI',
    });
    // @TODO: investigate why project does not render automatically in this case...
    emitter.emit('RERENDER');
  }, [executeAction, selectedAnnotations]);

  const onExportAnnotationDXF = useCallback(() => {
    exportDXF([identifier]);
  }, [exportDXF, identifier]);

  const onExportAnnotationsDXF = useCallback(() => {
    const identifiers = selectedAnnotations.map((annotation) => annotation.identifier);

    exportDXF(identifiers);
  }, [exportDXF, selectedAnnotations]);

  const annotationIdentifiers = useMemo(
    () =>
      selectedAnnotations.length > 1 ? selectedAnnotations.map((annotation) => annotation.identifier) : [identifier],
    [selectedAnnotations, identifier],
  );

  const [{ onToggleNameEditable }, { nameEditable, nameInputComponent }] = useRename({
    name: annotation.name,
    annotationIdentifier: annotation.identifier,
  });

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      submitRename();
      onClickAnnotation(
        { identifier, type: 'annotation', groupIdentifier },
        { isCtrl: event.ctrlKey || event.metaKey, isShift: event.shiftKey },
      );
    },
    [onClickAnnotation, identifier, submitRename, groupIdentifier],
  );

  const selectionInfo = useMemo(() => {
    return {
      onlyRootAnnotations: selectedAnnotations.every(
        (annotation) => !annotation.groupIdentifier && annotation.type === 'annotation',
      ),
      onlyGroupLevelAnnotations: selectedAnnotations.every((annotation) => annotation.groupIdentifier),
    };
  }, [selectedAnnotations]);

  const groupMenuItem = useMemo(
    () => (
      <SelectItemContent icon={FolderIcon} onClick={openCreateGroupModal}>
        <T _str="create group" swc />
      </SelectItemContent>
    ),
    [openCreateGroupModal],
  );
  const moveToGroupMenuItem = useMemo(
    () => (
      <SelectItemContent icon={FolderIcon} onClick={openMoveAnnotationsGroupModal}>
        <T _str="move to group" swc />
      </SelectItemContent>
    ),
    [openMoveAnnotationsGroupModal],
  );
  const moveOutGroupMenuItem = useMemo(
    () => (
      <SelectItemContent icon={FolderMinusIcon} onClick={onMoveAnnotationOutGroup}>
        <T _str="move out group" swc />
      </SelectItemContent>
    ),
    [onMoveAnnotationOutGroup],
  );

  if (nameEditable) return <>{nameInputComponent}</>;

  return (
    <>
      <ContextMenu
        popoverMenuDisabled={isReadOnly}
        buttonChild={
          <Draggable
            draggableId={`annotation_${annotation.identifier}_${annotation.groupIdentifier || ''}`}
            index={annotation.index}
            isDragDisabled={isReadOnly}
          >
            {(provided, snapshot) => (
              <div
                data-annotation-group-id={annotation.groupIdentifier}
                data-annotation-id={annotation.identifier}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={provided.draggableProps.style}
                className="w-full"
              >
                <SelectItemContent
                  onClick={onClick}
                  icon={ICONS_BY_TYPE[type]}
                  active={rootActive || active}
                  activeLevel={rootActive ? 1 : 0}
                  selectStyle="primary"
                  idle={!annotation.visible}
                  className={classNames(
                    !className?.includes('pl-') && 'pl-8',
                    snapshot.isDragging && 'bg-neon-green-300 dark:bg-black dark:text-white',
                    className,
                  )}
                  onDoubleClick={onToggleNameEditable}
                >
                  <>
                    <div className="overflow-hidden">{annotation.name}</div>
                    <AnnotationsIconsWrapper
                      identifier={annotation.identifier}
                      visible={annotation.visible}
                      annotation={annotation}
                    />
                  </>
                </SelectItemContent>
              </div>
            )}
          </Draggable>
        }
        panelClassName="divide-y"
      >
        {isMultiSelect ? (
          <>
            <div className="flex flex-col">
              <SelectItemContent icon={TrashIcon} onClick={onDeleteAnnotations}>
                <T _str="delete" swc />
              </SelectItemContent>
            </div>
            {selectionInfo.onlyRootAnnotations && groupMenuItem}
            {selectionInfo.onlyGroupLevelAnnotations && moveToGroupMenuItem}
            {selectionInfo.onlyGroupLevelAnnotations && moveOutGroupMenuItem}

            <div className="flex flex-col">
              <SelectItemContent icon={ArrowDownTrayIcon} onClick={onExportAnnotationsDXF}>
                <T _str="export to DXF" swc />
              </SelectItemContent>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col">
              <SelectItemContent icon={PencilIcon} onClick={onToggleNameEditable}>
                <T _str="rename" swc />
              </SelectItemContent>
            </div>
            <div className="flex flex-col">
              <UpdatePositionItems index={rootIndex} />
            </div>
            <div className="flex flex-col">
              {!groupIdentifier ? groupMenuItem : moveOutGroupMenuItem}
              {moveToGroupMenuItem}
            </div>
            <div className="flex flex-col">
              <SelectItemContent icon={ArrowDownTrayIcon} onClick={onExportAnnotationDXF}>
                <T _str="export to DXF" swc />
              </SelectItemContent>
            </div>
            <div className="flex flex-col">
              <SelectItemContent icon={TrashIcon} onClick={onDeleteAnnotation}>
                <T _str="delete" swc />
              </SelectItemContent>
            </div>
          </>
        )}
      </ContextMenu>
      <AnnotationGroupModal
        onClose={closeCreateGroupModal}
        open={createGroupModalOpen}
        annotationIdentifiers={annotationIdentifiers}
      />
      <MoveAnnotationsGroupModal
        onClose={closeMoveAnnotationsGroupModal}
        open={moveAnnotationsGroupModalOpen}
        annotationIdentifiers={annotationIdentifiers}
        ignoreGroupIdentifier={annotation.groupIdentifier}
      />
    </>
  );
};
export const Annotation = memo(Annotation_);
