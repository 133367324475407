import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMaxFiles } from '../../hooks/modules/project/usePointCloudDrop';
import { useUpload } from '../../hooks/useUpload';
import { ProjectModal } from '../../modules/project/ProjectModal';
import { T } from '../../translation/src';
import { useProjectDeleteMutation, useProjectInitializeMutation } from '../../types/graphqlTypes';
import { SubmitModal } from '../Modal/SubmitModal';
import { ProgressBar } from '../loaders/ProgressBar';
import { UserContext } from '../../contexts/UserContext';

const DEFAULT_TAB_TITLE = 'Pointorama';

interface UploadProgressModalProps {
  onClose: () => void;
  open?: boolean;
  files?: File[];
  isPythagorasFile?: boolean;
}
const UploadProgressModal_: React.FC<UploadProgressModalProps> = ({
  onClose,
  files,
  open: progressModalOpen,
  isPythagorasFile,
}) => {
  const { organisationId = '' } = useParams();
  const [initializeProject] = useProjectInitializeMutation();
  const [{ progress }, { uploadFiles, resetProgress, onCancelUpload }] = useUpload();
  const [projectCreateInfo, setProjectCreateInfo] = useState<{ fileNames: string[]; projectId: string } | undefined>();
  const [deleteProject] = useProjectDeleteMutation();
  const maxFiles = useMaxFiles();
  const windowFocussed = useRef(true);
  const uploadInterval = useRef<NodeJS.Timeout>();
  const uploadProjectId = useRef<string>();
  const currentUser = useContext(UserContext);

  const startUpload = useCallback(async () => {
    uploadProjectId.current = undefined;
    if (!files?.length || (!currentUser.isSuperAdmin && files.length > maxFiles)) return;
    const { data } = await initializeProject({ variables: { organisationId } });
    const projectId = data?.projectInitialize.id;
    uploadProjectId.current = projectId;
    if (!projectId) return;
    const uploadedFiles = await uploadFiles({
      files,
      projectId,
      isPythagorasFile,
    });
    // This means that the upload is cancelled
    if (!uploadProjectId.current) return;
    setProjectCreateInfo({ fileNames: (uploadedFiles || []).map(({ name }) => name), projectId });
  }, [isPythagorasFile, files, initializeProject, uploadFiles, organisationId, maxFiles, currentUser.isSuperAdmin]);

  useEffect(() => {
    if (progressModalOpen) startUpload();
  }, [progressModalOpen]);

  const onPreventBrowserClose = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = 'Are you sure you want to close the browser? Your upload will be cancelled.';
    return e.returnValue;
  }, []);

  const onReset = useCallback(() => {
    uploadProjectId.current = undefined;
    setProjectCreateInfo(undefined);
    resetProgress();
  }, [resetProgress]);

  const onSuccessProjectCreate = useCallback(() => {
    window.removeEventListener('beforeunload', onPreventBrowserClose);
    onClose();
    onReset();
  }, [onPreventBrowserClose, onClose, onReset]);

  const onCloseUploadProgressModal = useCallback(async () => {
    const projectId = uploadProjectId.current;
    uploadProjectId.current = undefined;
    onCancelUpload();
    if (uploadInterval.current) clearInterval(uploadInterval.current);
    if (projectId) {
      try {
        deleteProject({
          variables: { projectId },
          update: (cache) => cache.evict({ id: `Project:${projectId}` }),
        });
      } catch (e) {}
    }
    onReset();
    onClose();
  }, [deleteProject, onClose, onReset, onCancelUpload]);

  const projectModalOpen = !!projectCreateInfo;

  const onWindowFocus = useCallback(() => {
    document.title = DEFAULT_TAB_TITLE;
    if (uploadInterval.current) clearInterval(uploadInterval.current);
    windowFocussed.current = true;
  }, []);
  const onWindowBlur = useCallback(() => {
    windowFocussed.current = false;
  }, []);

  const open = progressModalOpen || projectModalOpen;

  useEffect(() => {
    if (open) window.addEventListener('beforeunload', onPreventBrowserClose);
    else window.removeEventListener('beforeunload', onPreventBrowserClose);
    return () => {
      window.removeEventListener('beforeunload', onPreventBrowserClose);
    };
  }, [onPreventBrowserClose, open]);
  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', onPreventBrowserClose);
    };
  }, [onPreventBrowserClose]);
  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);
    return () => {
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, [onWindowBlur, onWindowFocus]);
  useEffect(() => {
    if (projectModalOpen && !windowFocussed.current) {
      uploadInterval.current = setInterval(() => {
        document.title = 'Upload done!';
        setTimeout(() => {
          document.title = DEFAULT_TAB_TITLE;
        }, 1000);
      }, 2000);
    }
    return () => {
      if (uploadInterval.current) clearInterval(uploadInterval.current);
    };
  }, [progressModalOpen, projectModalOpen]);

  return (
    <>
      <SubmitModal
        open={progressModalOpen && !projectModalOpen}
        title={<T _str="Uploading pointclouds" />}
        onCancel={onCloseUploadProgressModal}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="text-sm text-gray-500">
            <T _str="We are uploading pointclouds. Please don't close this window while uploading. Clicking on cancel will stop the upload" />
          </div>
          <ProgressBar progress={progress} />
        </div>
      </SubmitModal>
      <ProjectModal
        key="projectmodal"
        onClose={onSuccessProjectCreate}
        createInfo={projectCreateInfo}
        open={projectModalOpen}
        isPythagorasFile={isPythagorasFile}
      />
    </>
  );
};
export const UploadProgressModal = memo(UploadProgressModal_);
